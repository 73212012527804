import Vue from 'vue'
import Vuex from 'vuex'

import {getCookie, setCookie, deleteCookie, localStorageSet, localStorageGet, localStorageRemove} from '../lib.js';

let store = function(params){
	params["store"] = new Vuex.Store({
		state: {
			authChecked: false,
			status: '',
			token: localStorageGet('token')||'',
		},
		actions: {
			checkAuth({commit, dispatch}){
				return new Promise((resolve, reject) => {
					if(!params["store"].state.authChecked && params["store"].state.status == ''){
						commit('auth_request');
						resolve();
						return true;
						fetch('/api/admin/checkauth/').then(stream => stream.json()).then(data => {
							//console.log(data);
							//console.log('auth check done');
							
							if(!data.auth){
								// session expired or not exists. logout
								localStorageRemove('token');
								dispatch('logout');
								resolve();
								return true;
							}
							
							let token = data.jwt_string;
							user.accessToken = token;
							
							localStorageSet('token', token);
							axios.defaults.headqers.common['Authorization'] = token;
							commit('auth_success', {token});
							resolve(data);
							return true;
						}).catch(error => {
							console.log(error);
							commit('auth_error');
							localStorageRemove('token');
							delete axios.defaults.headers.common['Authorization'];
							reject(erorr);
							return false;
						});
					} else {
						resolve();
						return true;
					}
				});
			},
			login({commit}, user){
				return new Promise((resolve, reject) => {
					commit('auth_request');
					
					fetch('/api/admin/login/', {
						mode: 'cors',
						cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
						credentials: 'include', // include, *same-origin, omit
						method: 'POST',
						body: JSON.stringify({
							password: user.password,
						}),
						headers: {
							'Content-Type': 'application/json'
						},
					}).then(stream => stream.json()).then(data => {
						//console.log(data);
						
						if(data.error && data.error != ''){
							commit('auth_error', data.error||'');
							localStorageRemove('token');
							delete axios.defaults.headers.common['Authorization'];
							reject(data.error||'');
							return false;
						}
						
						let token = data.token;
						user.accessToken = token;
						
						localStorageSet('token', token);
						axios.defaults.headers.common['Authorization'] = token;
						commit('auth_success', {token});
						resolve(data);
						return true;
					}).catch(error => {
						commit('auth_error', error);
						localStorageRemove('token');
						delete axios.defaults.headers.common['Authorization'];
						reject(error);
						return false;
					});
				});
			},
			logout({commit}){
				return new Promise((resolve, reject) => {
					fetch('/api/admin/logout/').then(stream => stream.json()).then(data => {
						commit('logout');
						localStorageRemove('token');
						delete axios.defaults.headers.common['Authorization'];
						resolve(data);
						return true;
					}).catch(error => {
						commit('auth_error', error);
						localStorageRemove('token');
						delete axios.defaults.headers.common['Authorization'];
						reject(error);
						return false;
					});
				});
			},
		},
		mutations: {
			auth_request(state){
				state.authChecked = false;
				state.status = 'loading';
			},
			auth_success(state, data){
				state.authChecked = true;
				state.status = 'success';
				state.token = data.token;
			},
			auth_error(state){
				state.authChecked = false;
				state.status = 'error';
			},
			logout(state){
				state.authChecked = false;
				state.status = '';
				state.token = '';
			},
		},
		getters: {
			isLoggedIn: state => !!state.token,
			authStatus: state => state.status,
		},
		modules: {
			page: {
				namespaced: true,
				state: () => ({}),
				mutations: {},
			},
		},
	});
	
	return params;
};

/* Init data */
store.init = function(params){
	let store = params["store"];
	
	/* set name */
	//store.state.page.Layout.modalOpen = false;
	
	return params;
}

/* Extends vue */
store.install = function(Vue, options){
	Vue.prototype.storeCommit = function(action, params){
		let arr = this.namespace.concat(action.split("/"));
		this.$store.commit(arr.join("/"), params);
	};
	
	Vue.prototype.storeDispatch = function(action, params){
		let arr = this.namespace.concat(action.split("/"));
		this.$store.dispatch(arr.join("/"), params);
	};
	
	Vue.prototype.getModel = function(){
		let arr = this.namespace.slice();
		let obj = this.$store.state;
		while(arr.length != 0){
			let key = arr.shift();
			if(obj[key] == undefined){
				obj = null;
				break;
			}
			obj = obj[key];
		}
		return obj;
	};
};

export default store;