<template>
	<div class="container pt-5 my-5">
		<b-card-group deck>
			<b-card header-tag="header">
				<template #header>
					<h3 class="mb-0 d-flex justify-content-between align-items-center">
						Настройки
					</h3>
				</template>
				<b-overlay :show="overlay" rounded="lg" variant="white">
					<b-form @submit="onSave">
						<div class="d-flex justify-content-between align-items-center">
							<div v-html="result"></div>
							<div class="my-2">
								<b-button variant="primary" type="submit">Сохранить</b-button>
							</div>
						</div>
						
						<b-form-group
							id="input-group-admin-password"
							label="Новый пароль администратора (если нужно сменить):"
							label-for="input-admin-passwprd"
						>
							<b-form-input
								id="input-admin-password"
								v-model="admin_password"
								type="text"
								autofocus
							></b-form-input>
						</b-form-group>
					</b-form>
				</b-overlay>
			</b-card>
		</b-card-group>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'AdminSettings',
	props: ['namespace'],
	data: () => ({
		overlay: false,
		
		admin_password: '',
		
		result: '',
    }),
	computed: {
		model(){
			return this.getModel();
		},
		token(){
			return this.$store.state.token;
		},
	},
	methods: {
		onSave(event){
			event.preventDefault();
			
			this.overlay = true;
			fetch('/api/admin/settings/', {
				method: 'POST',
				body: JSON.stringify({
					admin_password: this.admin_password,
				}),
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Bearer '+this.token,
				},
			}).then(stream => stream.json()).then(ret => {
				if(ret.success){
					this.admin_password = '';
					this.result = 'Изменения успешно сохранены';
				} else {
					if(ret.error) this.result = ret.error;
				}
				this.overlay = false;
			}).catch(error => {
				this.result = error;
				this.overlay = false;
				console.error(error);
			});
		},
	},
	beforeMount(){
		
	},
}
</script>