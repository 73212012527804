//import('https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.11/lodash.min.js');
//import './assets/js/lodash-4.17.11.min.js';
//import _ from 'lodash';

import './assets/js/date.format.js';

//import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap-vue/dist/bootstrap-vue.css';

import Vue from 'vue';

//import { microtime, genId } from './lib.js';

import axios from 'axios';
window.axios = require('axios');
Vue.prototype.$http = axios;
const token = localStorage.getItem('token');
if(token){
	//Vue.prototype.$http.defaults.headers.common['Authorization'] = token;
}

import { BootstrapVue, BootstrapVueIcons, IconsPlugin } from 'bootstrap-vue';
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(BootstrapVueIcons);
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// This imports all the layout components such as <b-container>, <b-row>, <b-col>:
import { LayoutPlugin } from 'bootstrap-vue'
Vue.use(LayoutPlugin)

// This imports <b-modal> as well as the v-b-modal directive as a plugin:
//import { ModalPlugin } from 'bootstrap-vue'
//Vue.use(ModalPlugin)

// This imports <b-card> along with all the <b-card-*> sub-components as a plugin:
import { CardPlugin } from 'bootstrap-vue'
Vue.use(CardPlugin)

// This imports directive v-b-scrollspy as a plugin:
import { VBScrollspyPlugin } from 'bootstrap-vue'
Vue.use(VBScrollspyPlugin)

// This imports the dropdown and table plugins
import { DropdownPlugin, TablePlugin } from 'bootstrap-vue'
Vue.use(DropdownPlugin)
Vue.use(TablePlugin)

import Vuex from 'vuex';
Vue.use(Vuex);

import VueRouter from 'vue-router';
Vue.use(VueRouter);

import Store from './store/store.js';
Vue.use(Store);

//Vue.config.productionTip = false;
Vue.config.debug = true;
Vue.config.devtools = true;

import App from './App.vue';
import Router from './router.js';

var params = {
	render: h => h(App),
};
params = Store(params);
params = Router(params);
params = Store.init(params);

var app = new Vue(params).$mount('#app');

window["appInstance"] = app;
window["storeInstance"] = params["store"];
