<template>
	<div>
		<b-navbar toggleable="lg" type="light" variant="light" fixed="top">
			<div class="container">
				<router-link custom :to="{path: '/'}" v-slot="{href, route, navigate, isActive, isExactActive}">
					<b-navbar-brand :href="href" @click="navigate">
						CertGo
					</b-navbar-brand>
				</router-link>
				<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
				<b-collapse id="nav-collapse" is-nav>
					<b-navbar-nav>
						<router-link custom :to="{path: '/'}" v-slot="{href, route, navigate, isActive, isExactActive}">
							<b-nav-item :href="href" @click="navigate" :class="[{'active': isExactActive}]">Поиск сертификатов по ИИН</b-nav-item>
						</router-link>
						<!--<b-nav-item href="#" disabled>Disabled</b-nav-item>-->
					</b-navbar-nav>
					<b-navbar-nav class="ml-auto" v-if="isLoggedIn">
						<router-link custom :to="{path: '/certs'}" v-slot="{href, route, navigate, isActive, isExactActive}">
							<b-nav-item :href="href" @click="navigate" :class="[{'active': isExactActive}]">База сертификатов</b-nav-item>
						</router-link>
						<router-link custom :to="{path: '/settings'}" v-slot="{href, route, navigate, isActive, isExactActive}">
							<b-nav-item :href="href" @click="navigate" :class="[{'active': isExactActive}]">Настройки</b-nav-item>
						</router-link>
						<router-link custom :to="{path: '/logout'}" v-slot="{href, route, navigate, isActive, isExactActive}">
							<b-nav-item :href="href" @click="navigate">Выйти</b-nav-item>
						</router-link>
					</b-navbar-nav>
				</b-collapse>
			</div>
		</b-navbar>
		<div>
			<router-view/>
		</div>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'Layout',
	props: ['namespace'],
	data: () => ({
		
	}),
	buildStore: (state) => {
		if(state == null || state == undefined){
			state = {
				
			};
		}
		
		var res = {
			namespaced: true,
			state: () => {
				return state;
			},
			modules: {},
			mutations: {},
		}
		
		return res;
	},
	computed: {
		model(){
			return this.getModel();
		},
		isLoggedIn(){
			return this.$store.getters.isLoggedIn;
		},
	},
	methods: {
		
	},
	created(){
		this.$http.interceptors.response.use(undefined, function(err){
			return new Promise(function(resolve, reject){
				if(err.status === 401 && err.config && !err.config.__isRetryRequest){
					this.$store.dispatch('logout');
				}
				throw err;
			});
		});
	},
}
</script>
