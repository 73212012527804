<template>
	<div class="container pt-5 my-5">
		<b-card-group deck>
			<b-card header-tag="header">
				<template #header>
					<h3 class="mb-0 d-flex justify-content-between align-items-center">
						Изменение сертификата
						<div>
							<router-link custom :to="{path: '/certs'}" v-slot="{href, route, navigate, isActive, isExactActive}">
								<b-button :href="href" @click="navigate">Назад</b-button>
							</router-link>
						</div>
					</h3>
				</template>
				<b-overlay :show="overlay" rounded="lg" variant="white">
					<b-form @submit="onSave">
						<div class="d-flex justify-content-between align-items-center">
							<div v-html="result"></div>
							<div class="my-2">
								<b-button variant="danger" class="mr-1" @click="onDelete()">Удалить</b-button>
								<b-button variant="primary" type="submit">Сохранить</b-button>
							</div>
						</div>
						
						<b-form-group
							id="input-group-id"
							label="ID:"
							label-for="input-id"
						>
							<b-form-input
								id="input-id"
								v-model="id"
								type="number"
								readonly
							></b-form-input>
						</b-form-group>
						
						<b-form-group
							id="input-group-iin"
							label="ИИН:"
							label-for="input-iin"
						>
							<b-form-input
								id="input-iin"
								v-model="iin"
								:state="Boolean(iin)"
								placeholder="Например: 123456789012"
								type="text"
								required
								autofocus
							></b-form-input>
						</b-form-group>
						
						<b-form-group
							id="input-group-name"
							label="Название сертификата:"
							label-for="input-name"
						>
							<b-form-input
								id="input-name"
								v-model="name"
								:state="Boolean(name)"
								placeholder="Например: Сертификат"
								type="text"
								required
							></b-form-input>
						</b-form-group>
						
						<b-form-group
							id="input-group-cert"
							label="Файл сертификата (PDF) (если нужно заменить):"
							label-for="input-cert"
						>
						
							<b-form-file
								id="input-cert"
								v-model="cert"
								placeholder="Выберите файл или перетащите его сюда..."
								drop-placeholder="Перетащите файл сюда..."
							></b-form-file>
						</b-form-group>
					</b-form>
				</b-overlay>
			</b-card>
		</b-card-group>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'AdminCertEdit',
	props: ['namespace'],
	data: () => ({
		overlay: false,
		
		id: null,
		iin: '',
		name: '',
		cert: null,
		
		result: '',
    }),
	computed: {
		model(){
			return this.getModel();
		},
		token(){
			return this.$store.state.token;
		},
	},
	methods: {
		loadData(id){
			this.overlay = true;
			fetch('/api/admin/certs/'+id+'/', {
				headers: {
					Authorization: 'Bearer '+this.token,
				},
			}).then(stream => stream.json()).then(item => {
				this.id = item.id;
				this.iin = item.iin;
				this.name = item.name;
				
				this.overlay = false;
			}).catch(error => {
				alert('Ошибка! '+error);
				console.error(error);
			});
		},
		
		onSave(event){
			event.preventDefault();
			
			this.result = 'Сохранение...';
			this.overlay = true;
			
			let formData = new FormData();
			formData.append('iin', this.iin);
			formData.append('name', this.name);
			if(this.cert) formData.append('cert', this.cert);
			
			fetch('/api/admin/certs/'+this.id+'/', {
				method: 'POST',
				body: formData,
				headers: {
					Authorization: 'Bearer '+this.token,
				},
			}).then(stream => stream.json()).then(ret => {
				if(ret.success) this.result = 'Изменения успешно сохранены';
				this.overlay = false;
			}).catch(error => {
				console.error(error);
				this.overlay = false;
				this.result = 'Ошибка! '+error;
			});
		},
		
		onDelete(){
			if(confirm('Вы уверены?')){
				this.overlay = true;
				fetch('/api/admin/certs/'+this.id+'/', {
					method: 'DELETE',
					headers: {
						Authorization: 'Bearer '+this.token,
					},
				}).then(stream => stream.json()).then(ret => {
					if(ret.success){
						this.$router.push('/certs');
					} else {
						this.overlay = false;
					}
				}).catch(error => {
					console.error(error);
					this.overlay = false;
					this.result = 'Ошибка! '+error;
				});
			}
		},
	},
	beforeMount(){
		this.loadData(this.$route.params.id);
	},
}
</script>