<template>
	<div class="container pt-5 my-5">
		<b-card-group deck>
			<b-card header-tag="header">
				<template #header>
					<h3 class="mb-0 d-flex justify-content-between align-items-center">
						Добавление сертификата
						<div>
							<router-link custom :to="{path: '/certs'}" v-slot="{href, route, navigate, isActive, isExactActive}">
								<b-button :href="href" @click="navigate">Назад</b-button>
							</router-link>
						</div>
					</h3>
				</template>
				<b-overlay :show="overlay" rounded="lg" variant="white">
					<b-form @submit="onAdd">
						<div class="d-flex justify-content-between align-items-center">
							<div v-html="result"></div>
							<div class="my-2">
								<b-button variant="primary" type="submit">Добавить</b-button>
							</div>
						</div>
						
						<b-form-group
							id="input-group-iin"
							label="ИИН:"
							label-for="input-iin"
						>
							<b-form-input
								id="input-iin"
								v-model="iin"
								:state="Boolean(iin)"
								placeholder="Например: 123456789012"
								type="text"
								required
								autofocus
							></b-form-input>
						</b-form-group>
						
						<b-form-group
							id="input-group-name"
							label="Название сертификата:"
							label-for="input-name"
						>
							<b-form-input
								id="input-name"
								v-model="name"
								:state="Boolean(name)"
								placeholder="Например: Сертификат"
								type="text"
								required
							></b-form-input>
						</b-form-group>
						
						<b-form-group
							id="input-group-cert"
							label="Файл сертификата (PDF):"
							label-for="input-cert"
						>
						
							<b-form-file
								id="input-cert"
								v-model="cert"
								:state="Boolean(cert)"
								placeholder="Выберите файл или перетащите его сюда..."
								drop-placeholder="Перетащите файл сюда..."
								required
							></b-form-file>
						</b-form-group>
					</b-form>
				</b-overlay>
			</b-card>
		</b-card-group>
	</div>
</template>

<style lang="css">

</style>

<script>
export default {
	name: 'AdminCertAdd',
	props: ['namespace'],
	data: () => ({
		overlay: false,
		
		iin: '',
		name: '',
		cert: null,
		
		result: '',
    }),
	computed: {
		model(){
			return this.getModel();
		},
		token(){
			return this.$store.state.token;
		},
	},
	methods: {
		onAdd(event){
			event.preventDefault();
			
			this.result = 'Добавление...';
			this.overlay = true;
			
			let formData = new FormData();
			formData.append('iin', this.iin);
			formData.append('name', this.name);
			formData.append('cert', this.cert);
			
			fetch('/api/admin/certs/', {
				method: 'POST',
				body: formData,
				headers: {
					Authorization: 'Bearer '+this.token,
				},
			}).then(stream => stream.json()).then(ret => {
				if(ret.success){
					//this.$router.push('/certs/'+ret.id);
					this.$router.push('/certs');
				} else {
					if(ret.error) this.result = ret.error;
					this.overlay = false;
				}
			}).catch(error => {
				this.result = 'Ошибка! '+error;
				this.overlay = false;
				console.error(error);
			});
		},
	},
	beforeMount(){
		
	},
}
</script>