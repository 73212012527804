<template>
	<div class="container pt-5 my-5">
		<div class="form-search-certs">
			<h3 class="mb-3 font-weight-normal text-center">Введите ИИН</h3>
			<label for="inputIIN" class="sr-only">Email address</label>
			<input type="number" id="inputIIN" class="form-control text-center iin" placeholder="987654321098" required autofocus v-model="iin" @keyup.enter="search()" />
			<button class="mt-3 btn btn-lg btn-primary btn-block" @click="search()" :disabled="overlay">Поиск сертификатов</button>
		</div>
		<div class="text-center" v-if="overlay">
			<h3 class="mt-5 mb-3  font-weight-normal">Идет поиск сертификатов по данному ИИН...</h3>
			<b-spinner class="mt-5" style="width: 5rem; height: 5rem;" variant="primary" label="Идет поиск..."></b-spinner>
		</div>
		<div v-if="!overlay && step == 1 && certs.length > 0">
			<h3 class="mt-5 mb-3 font-weight-normal text-center">Найденные сертификаты</h3>
			<b-list-group>
				<b-list-group-item v-for="cert in certs">
					<div class="row d-flex align-items-center">
						<div class="col-12 col-md-2 col-lg-1 text-center text-md-left mb-3 mb-md-0">
							<img src="../../static/img/certificate.svg" style="width: 50px; height: 50px;"  />
						</div>
						<div class="col-12 col-md-5 col-lg-7 mb-3 text-center text-md-left mb-md-0">
							<b>{{cert.name}}</b>
						</div>
						<div class="col-12 col-md-5 col-lg-4 text-center text-md-right">
							<a :href="'/storage/certs/'+cert.file" class="btn btn-lg btn-info" target="_blank">Просмотр</a>
							<a :href="'/storage/certs/'+cert.file" class="btn btn-lg btn-success" style="" download>Скачать</a>
						</div>
					</div>
				</b-list-group-item>
			</b-list-group>
		</div>
		<div class="text-center" v-if="!overlay && step == 1 && certs.length <= 0">
			<h3 class="mt-5 mb-3 font-weight-normal">Сертификатов по данному ИИН не найдено</h3>
		</div>
	</div>
</template>

<style lang="css" scoped>
.form-search-certs {
	width: 100%;
	max-width: 330px;
	padding: 15px;
	margin: 0 auto;
}
.form-search-certs .form-control {
	position: relative;
	box-sizing: border-box;
	height: auto;
	padding: 10px;
	font-size: 16px;
}
.form-search-certs .form-control:focus {
	z-index: 2;
}
.form-search-certs .iin {
	font-family: 'Courier New';
}

/* Chrome, Safari, Edge, Opera */
.form-search-certs input[type=number]::-webkit-outer-spin-button,
.form-search-certs input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.form-search-certs input[type=number] {
  -moz-appearance: textfield;
}
</style>

<script>
export default {
	name: 'Certs',
	props: ['namespace'],
	data: () => ({
		overlay: false,
		step: 0,
		
		iin: '',
		
		certs: [],
    }),
	buildStore: (state) => {
		if(state == null || state == undefined){
			state = {
				
			};
		}
		
		var res = {
			namespaced: true,
			state: () => {
				return state;
			},
			modules: {},
			mutations: {},
		}
		
		return res;
	},
	computed: {
		model(){
			return this.getModel();
		},
		isLoggedIn(){
			return this.$store.getters.isLoggedIn;
		},
	},
    methods: {
		search(){
			if(this.iin != '' && !this.overlay){
				this.overlay = true;
				this.certs = [];
				
				fetch('/api/certs/', {
					mode: 'cors',
					cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
					credentials: 'include', // include, *same-origin, omit
					method: 'POST',
					body: JSON.stringify({
						iin: this.iin,
					}),
					headers: {
						'Content-Type': 'application/json'
					},
				}).then(stream => stream.json()).then(data => {
					//console.log(data);
					
					this.certs = data.list;
					this.overlay = false;
					this.step = 1;
					
				}).catch(error => {
					alert('Ошибка! '+error);
					console.log(error);
				});
			}
		},
	},
	beforeMount(){
		
	},
	components: {
		
	},
}
</script>