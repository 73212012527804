import Vue from 'vue';
import VueRouter from 'vue-router';

import {localStorageSet, localStorageGet, localStorageRemove} from './lib.js';

import Layout from './layouts/Layout.vue';

import Certs from './pages/Certs.vue';
import Login from './pages/Login.vue';
import AdminCerts from './pages/AdminCerts.vue';
import AdminCertAdd from './pages/AdminCertAdd.vue';
import AdminCertEdit from './pages/AdminCertEdit.vue';
import AdminSettings from './pages/AdminSettings.vue';

import PageNotFound from './pages/PageNotFound.vue';

export default function(params)
{
	params["router"] = new VueRouter({
		mode: 'history',
		base: '/',
		routes:
		[
			{
				path: '/',
				component: Layout,
				children: [
					{
						path: '',
						component: Certs,
						props: {default: true, namespace: ["page", "NewsItem"]},
					},
					{
						path: '/login',
						component: Login,
						meta: {
							guest: true,
						},
						props: {default: true, namespace: ["page", "Login"]},
					},
					{
						path: '/certs',
						component: AdminCerts,
						meta: {
							requiresAuth: true,
						},
						props: {default: true, namespace: ["page", "AdminCerts"]},
					},
					{
						path: '/certs/add',
						component: AdminCertAdd,
						meta: {
							requiresAuth: true,
						},
						props: {default: true, namespace: ["page", "AdminCertAdd"]},
					},
					{
						path: '/certs/:id',
						component: AdminCertEdit,
						meta: {
							requiresAuth: true,
						},
						props: {default: true, namespace: ["page", "AdminCertEdit"]},
					},
					{
						path: '/settings',
						component: AdminSettings,
						meta: {
							requiresAuth: true,
						},
						props: {default: true, namespace: ["page", "AdminSettings"]},
					},
				],
			},
			{
				path: '/logout',
				beforeEnter(to, from, next){
					params["store"].dispatch('logout').then(() => {
						next('/login');
					});
				},
			},
			{
				path: '*',
				component: Layout,
				children: [
					{
						path: '',
						component: PageNotFound,
					},
				],
				props: {default: true, namespace: ["page", "PageNotFound"]},
			},
		],
		//linkActiveClass: 'active',
		//linkExactActiveClass: 'active',
	});
	
	params["store"].registerModule(["page", "Layout"], Layout.buildStore());
	//params["store"].registerModule(["page", "Home"], Certs.buildStore());
	//params["store"].registerModule(["page", "PageNotFound"], PageNotFound.buildStore());
	
	params["router"].beforeEach((to, from, next) => {
		params["store"].dispatch('checkAuth').then(() => {
			if(to.matched.some(record => record.meta.requiresAuth)){
				if(!params["store"].getters.isLoggedIn){
					next({
						path: '/login',
						params: {
							nextUrl: to.fullPath,
						},
					})
				} else {
					next()
				}
			} else if(to.matched.some(record => record.meta.guest)){
				if(!params["store"].getters.isLoggedIn){
					next()
				} else {
					next('/')
				}
			} else {
				next() 
			}
		});
	});
	
	return params;
}
