<template>
	<div class="container pt-5 my-5">
		<b-card-group deck>
			<b-card header-tag="header" no-body>
				<template #header>
					<h3 class="mb-0 d-flex justify-content-between align-items-center">
						База сертификатов
						<div>
							<b-badge>{{certsCount}}</b-badge>
							<router-link custom :to="{path: '/certs/add'}" v-slot="{href, route, navigate, isActive, isExactActive}">
								<b-button :href="href" variant="primary" @click="navigate">Добавить</b-button>
							</router-link>
						</div>
					</h3>
				</template>
				<b-card-body>
					<input type="text" v-model="certsSearchText" class="form-control" placeholder="Поиск по ИИН, названию сертификата" @keyup.enter="setPage(1, true)" autofocus />
				</b-card-body>
				<b-overlay :show="overlay" rounded="lg" variant="white">
					<b-list-group flush>
						<router-link custom :to="{path: '/certs/'+cert.id}" v-slot="{href, route, navigate, isActive, isExactActive}"
							v-for="cert in certs"
							:key="cert.id"
						>
							<b-list-group-item :href="href" @click="navigate">
								ИИН: {{cert.iin}}, {{cert.name}}
								<i class="fa fa-chevron-right mt-1 pull-right"></i>
							</b-list-group-item>
						</router-link>
					</b-list-group>
					<b-card-body>
						<nav>
							<ul class="pagination justify-content-center mb-0">
								<li v-for="p in pagination.pages" @click.prevent="setPage(p)" v-bind:class="['page-item', {active: p == pagination.currentPage}]">
									<a class="page-link" href="#" v-if="p != pagination.currentPage">{{p}}</a>
									<span class="page-link" v-else>{{p}}</span>
								</li>
							</ul>
						</nav>
						<div class="text-center" v-if="!overlay && certs.length <= 0">База сертификатов пуста</div>
					</b-card-body>
				</b-overlay>
			</b-card>
		</b-card-group>
	</div>
</template>

<style lang="css">

</style>

<script>
import { decl1, decl2 } from '../assets/js/scripts.js';
import _ from 'lodash';

export default {
	name: 'AdminCerts',
	props: ['namespace'],
	data: () => ({
		overlay: true,
		certs: [],
		certsCount: 0,
		
		certsSearchText: '',
		certsLimit: 15,
		pagination: {
			startIndex: 0,
			endIndex: 0,
			currentPage: 0,
			pages: [],
		},
    }),
	computed: {
		model(){
			return this.getModel();
		},
		token(){
			return this.$store.state.token;
		},
	},
	methods: {
		setPage(p, reload = false){
			if((p != this.pagination.currentPage && !reload) || reload){
				this.overlay = true;
				fetch('/api/admin/certs/?' + new URLSearchParams({
					search: this.certsSearchText,
					limit: 1,
				}), {
					headers: {
						Authorization: 'Bearer '+this.token,
					},
				}).then(stream => stream.json()).then(data => {
					this.certsCount = data.count;
					this.pagination = this.paginator(this.certsCount, p);
					this.overlay = false;
				}).catch(error => {
					alert('Ошибка! '+error);
					this.overlay = false;
					console.error(error);
				});
			}
		},
		paginator(totalItems, currentPage){
			var startIndex = (currentPage - 1) * this.certsLimit;
			var endIndex = Math.min(startIndex + this.certsLimit - 1, totalItems - 1);
			
			this.overlay = true;
			fetch('/api/admin/certs/?' + new URLSearchParams({
				search: this.certsSearchText,
				start: startIndex,
				limit: this.certsLimit,
			}), {
				headers: {
					Authorization: 'Bearer '+this.token,
				},
			})
			.then(stream => stream.json()).then(data => {
				this.certs = data.list;
				this.overlay = false;
			}).catch(error => {
				alert('Ошибка! '+error);
				this.overlay = false;
				console.error(error);
			});
			
			return {
				currentPage: currentPage,
				startIndex: startIndex,
				endIndex: endIndex,
				pages: _.range(1, Math.ceil(totalItems / this.certsLimit) + 1),
			};
		},
	},
	beforeMount(){
		this.setPage(1);
	},
}
</script>